
import { defineComponent, onMounted, ref } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import ProgressLevelForm from "@/components/ProgressLevelForm.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { lessonColumns } from "@/columns";
import { routeNames } from "@/router";
import { ProgressLevelModel } from "@/models";
import { restClient } from "@/store/actions";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "progress-level-form": ProgressLevelForm,
        "loading-indicator": LoadingIndicator
    },
    props: {
        progressLevelId: { type: Number, required: true }
    },
    setup(props) {
        const model = ref<ProgressLevelModel>({});
        const requestFilter = ref<ProgressLevelModel>({});

        onMounted(async () => {
            if (props.progressLevelId === 0) {
                model.value = {
                    id: 0,
                    name: "",
                    imageUrl: "",
                    pointsToEarn: 0
                };
            } else {
                const response = await restClient.getJson<ProgressLevelModel>(
                    `/api/admin/ProgressLevel/${props.progressLevelId}`
                );

                if (response.data) {
                    model.value = response.data;
                    requestFilter.value = {
                        id: model.value.id,
                        name: model.value.name,
                        imageUrl: model.value.imageUrl,
                        pointsToEarn: model.value.pointsToEarn
                    };
                }
            }
        });

        return {
            lessonColumns,
            routeNames,
            model,
            requestFilter
        };
    }
});
