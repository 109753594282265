
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref
} from "vue";
import { Form, FormActions } from "vee-validate";
import { useRouter } from "vue-router";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import {
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadVue3
} from "@progress/kendo-vue-upload";
import {
    createImageFormData,
    promptDelete,
    pngUploadFileRestrictions,
    createUniqueUrl,
    PROGRESS_LEVEL_IMAGE_TOOLTIP_MESSAGE,
    createOrUpdateModel
} from "@/helpers";
import { ProgressLevelModel } from "@/models";
import { routePaths } from "@/router";
import { AppGetterTypes, useStore } from "@/store";
import { restClient } from "@/store/actions";

import UploadPreview from "./UploadPreview.vue";
import InformationTooltipVue from "./InformationTooltip.vue";

export default defineComponent({
    props: {
        progressLevel: {
            type: Object as PropType<ProgressLevelModel>,
            required: true
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": UploadVue3,
        "upload-preview": UploadPreview,
        "information-tooltip": InformationTooltipVue
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const model: ProgressLevelModel = reactive(props.progressLevel);

        const rules = computed(
            () =>
                store.getters[AppGetterTypes.validationRules]
                    ?.progressLevelModel
        );

        const isNew = model.id === 0;

        // Image upload
        const imageFile = ref<UploadFileInfo>();
        const previewSource = ref<string>();
        const imageHasChanged = ref(false);

        onMounted(() => {
            // If editing, set the preview image with a timestamp to cache bust
            if (!isNew && model?.imageUrl) {
                previewSource.value = createUniqueUrl(model.imageUrl);
            }

            // If editing, set the preview logo image with a timestamp to cache bust
            if (!isNew && model?.imageUrl) {
                previewSource.value = createUniqueUrl(model.imageUrl);
            }
        });

        // Submit Form
        const onSubmit = async (
            values: ProgressLevelModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            // If the image has changed, ensure it is valid and present
            if (
                (!imageFile.value && imageHasChanged.value) ||
                imageFile.value?.validationErrors
            ) {
                return;
            }

            // Trigger validation for new progress level on submit if no logo image is selected
            if (isNew && !imageFile.value) {
                imageHasChanged.value = true;
            }

            // If the logo image has changed, ensure it is valid and present
            if (
                (!imageFile.value && imageHasChanged.value) ||
                imageFile.value?.validationErrors
            ) {
                return;
            }

            // Save progress level data
            const response = await createOrUpdateModel<ProgressLevelModel>(
                model,
                "/api/admin/ProgressLevel"
            );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // If the image has changed and there are no errors, upload the new one
            if (imageHasChanged.value && imageFile.value) {
                const formData = createImageFormData(imageFile.value);

                // Save image
                const imageUploadResponse = await restClient.postJson(
                    `/api/admin/ProgressLevelImage?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(imageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }

            router.push(routePaths.progressLevelPath);
        };

        // Prompt user to delete progress level
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete the progress level?"
                )
            ) {
                const response = await restClient.delete(
                    `/api/admin/ProgressLevel/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                router.push(routePaths.progressLevelPath);
            }
        };

        // Add image to upload component and build preview
        const addImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            imageFile.value = newImage;
            imageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        previewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new image from upload component
        const removeImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this image?” "
                )
            ) {
                imageFile.value = event.newState[0];
                previewSource.value = undefined;
            }
        };

        return {
            onSubmit,
            handleDelete,
            model,
            rules,
            routePaths,
            isNew,
            addImage,
            removeImage,
            imageFile,
            previewSource,
            imageHasChanged,
            pngUploadFileRestrictions,
            PROGRESS_LEVEL_IMAGE_TOOLTIP_MESSAGE
        };
    }
});
